.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #1c4592;
  color: #cee9fd;
  padding: 20px;
  text-align: center;
  box-sizing: border-box;
}

.link {
  color: #cee9fd;
  text-decoration: none;
  font-weight: bold;
}

.link:hover {
  text-decoration: underline;
}
