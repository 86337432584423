.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px 20px 130px;
}

.heading {
  font-size: 24px;
  font-weight: bold;

  &.dark {
    color: white;
  }
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0px 20px 130px;
  margin-top: 1.5rem;
}
