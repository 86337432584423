header {
  background: #1c4592;
  padding: 10px 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  padding-left: 8%;
  padding-right: 8%;
}

.heading {
  font-size: auto;
  color: #fbfbfe;
}

@media screen and (max-width: 600px) {
  header {
    padding: 10px;
  }
  header h1 {
    font-size: 16px; /*Adjust the font size for smaller screens*/
  }
  .btn {
    padding: 0.6rem 1.2rem; /* Adjust the padding for smaller screens */
    font-size: 14px; /* Adjust the font size for smaller screens */
  }
}
