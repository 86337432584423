@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  transition: opacity 1s, background 1s;

  &.light-mode {
    background: #000000; //#04112c
  }
}

:root[data-theme="light"] {
  --text: #130901;
  --background: #ffffff;
  --primary: #873503;
  --secondary: #fdd7bf;
  --accent: #ea5d06;
}
:root[data-theme="dark"] {
  --text: #fef4ec;
  --background: #000000;
  --primary: #fcab78;
  --secondary: #401a02;
  --accent: #f96d15;
}

.App {
  text-align: center;
}

button:hover {
  /*This is for all the buttons */
  background-color: #949494;
}

p {
  margin: 0;
}
