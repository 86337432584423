.button {
  border-radius: 20px;
  box-shadow: 0 7px 20px 0 hsla(240, 5%, 41%, 0.637);
  padding: 10px;
  transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275),
    background 0.5s ease, box-shadow 0.5s ease;
  height: 40px;
  width: 100px;
  margin: 10px;

  &.dark {
    background: rgba(45, 55, 87, 0.589);
    overflow: hidden;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 20px 0px;

    &.active {
      background-color: #209ef8;
    }
  }

  &.active {
    background-color: forestgreen;
  }
}

.button:hover {
  transform: scale(1.15);
}
