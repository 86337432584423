/* UpdateForm.css */
.form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;


  position: relative;
}


.authentication-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(0, 0, 0);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popup-content {
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: #00a2ff -70px 0px 800px;
}

form {
  width: 300px;
  padding: 50px;
  padding-right: 50px;
  display: flex;
  flex-direction: column;
  background-color: #022945;
  border-radius: 30px;
  box-shadow: -20px 0px 90px #209ef8;
}

label {
  margin-bottom: 10px;
  color: #ffffff;
}

input {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #209ef8;
  border-radius: 5px;
}

button {
  background-color: #209ef8;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}